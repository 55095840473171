import { MatSnackBarConfig } from "@angular/material/snack-bar";

export const config = {
  siteName: 'Служба бытового обслуживания',
  domain: 'НА-ЧАС.БЕЛ',
  workTime: 'c 09:00 до 21:00 Без выходных',
  phone: '+375(33)99-44-665',
  operator: 'МТС',
  snackBarConfig: <MatSnackBarConfig>{
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
    duration: 3000,
    panelClass: ['snack-bar-panel'],
  },
  logoUrl: "assets/img/logo.png",
  price: {
    start: '5',
    end: '125',
  },
  offerCount: '128',
  currency: 'BYN',
  country: '',
  addressCountry: "BY",
  addressRegion: "Минская область",
  addressLocality: "Минск",
  streetAddress: "ул. Инатенко, 7",
  postalCode: "220035",
  reviewCount: "156",
  ratingValue: "4.82",
  areaCodeISO: 'BY'
}
